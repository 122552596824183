<template>
    <div @click="$emit('close')" style="background-color: rgba(29, 29, 29, .25)" class="backdrop-blur-[2px] fixed w-full h-full z-20"></div>
    <div class="bg-[#02130E] px-4 py-3 border-[1px] border-[#42413f] ml-[70px] absolute top-[50%] left-[50%] translate-x-[-50%] z-30 translate-y-[-50%] w-[350px] rounded-[10px]">
        <div>
            <div class="text-[12px] text-center font-medium text-[#FFF] mb-[10px] pt-[10px]">ОПЛАТА ВНЕСКУ ГРАВЦЯ</div>
            <div class="text-white font-bold w-full px-[10px] text-[24px] flex justify-between">
                <div>
                    {{ store.student.name + ' ' + store.student.surname }}
                </div>
                <div class="truncate">
                    {{ store.group.name }}
                </div>
            </div>
            <!-- <div class="mt-[0px]">
                <div class="text-white text-[36px] font-bold text-center">{{ store.studentBalance < 0 ? (store.studentBalance * -1).toFixed(2) : 0.00 }}</div>
                <div class="text-white text-center text-[10px]">
                    (за попередній місяць)
                </div>
                <button @click="generateDebtTx" :disabled="+store.studentBalance >= 0" class="bg-[#5AC47D] disabled:opacity-70 text-white font-semibold w-full py-1.5 mt-[30px] rounded-[10px]">
                    Провести оплату
                </button>
            </div> -->
        </div>
        <div>
            <div class="bg-[#A1D9F7] text-center text-[#02130E] font-semibold w-full py-1.5 mt-[30px] rounded-[10px]">
                Виберіть тариф
            </div>
            <div class="mt-[20px]">
                <select v-model="currentTariff" name="tariff" id="tariff" class="outline-none border-none text-[#FFF] placeholder:text-[#FFF] text-[16px] w-full text-center placeholder:opacity-60 bg-transparent font-bold mt-[10px] mb-[10px]">
                    <option v-for="tariff in allTariffs" :value="tariff.id" class="bg-[#252526]">{{ tariff.name }}</option>
                </select>
            </div>
            <!-- <div class="text-[#FFF] text-center text-[14px]">
                (введіть суму, яку необхідно внести)
            </div> -->
            <button @click="createTransaction" class="bg-[#5AC47D] text-[#02130E] mb-[10px] font-semibold w-full py-1.5 mt-[30px] rounded-[10px]">
                Сплатити    
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useMainStore } from '../../store/mainStore'
    import { createWayforpayTransaction, getTariffsByRoleId } from '../../services/apiRequests'

    export default {
        name: 'AddPaymentPopup',

        setup() {
            const store = useMainStore()
            const summ = ref(null)

            const generateCustomTx = async () => {
                const res = await createWayforpayTransaction(
                    summ.value,
                    store.student.student_id,
                    store.student.name,
                    store.student.surname,
                    store.student.email,
                    store.student.phone,
                )
                console.log(res.data)
                window.open(res.data.payment.url, '_parent')
                alert('Платіжна система не підключена. Оплачувати наразі неможливо')
            }

            const generateDebtTx = async () => {
                if (store.studentBalance >= 0) return
                const res = await createWayforpayTransaction(
                    store.studentBalance * -1,
                    store.student.student_id,
                    store.student.name,
                    store.student.surname,
                    store.student.email,
                    store.student.phone,
                )
                console.log(res)
                window.open(res.data.payment.url, '_parent')
                alert('Платіжна система не підключена. Оплачувати наразі неможливо')
            }

            const allTariffs = ref([])

            const currentTariff = ref(null)

            const createTransaction = async () => {
                console.log('currentTariff.value ---------- ', currentTariff.value)

                const currentTariffObj = allTariffs.value.find(tariff => tariff.id === currentTariff.value)
                console.log('currentTariffObj ---------- ', currentTariffObj)
                const res = await createWayforpayTransaction(
                    +currentTariffObj.price,
                    store.student.student_id,
                    store.student.name,
                    store.student.surname,
                    store.student.email,
                    store.student.phone,
                    0,
                    0,
                    currentTariffObj.id,
                    currentTariffObj.name
                )
                console.log('res.data ---------- ', res.data)
                window.open(res.data.payment.url, '_parent')
                router.push('/payment-redirect')
                // alert('Платіжна система не підключена. Оплачувати наразі неможливо')
            }

            onMounted(async () => {
                const res = await getTariffsByRoleId(store.student.tariff_role_id)
                allTariffs.value = res.data
            })

            return {
                store,
                summ,
                allTariffs,
                currentTariff,
                createTransaction,
                generateCustomTx,
                generateDebtTx
            }
        }
    }
</script>
